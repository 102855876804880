<template>
  <div class="boss">
    <!-- 客服小海豚 -->
    <p class="kf">客服小海豚</p>

    <!-- 内容 -->
    <div class="content" v-for="(item,index) in QAData" :key="index">
      <p>{{item.q}}</p>
      <div>答：{{item.a}}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      QAData: [
        {
          q: "1、评分解读",
          a: "0-100分，这里评分的意思是用户资质的评分，分数越高表示信用越好，反之，评分越低表示负面记录越多。",
        },
        {
          q: "2、为什么我的评分是0？",
          a: "0分表示负面记录较多；或者历史记录较差；或者信用异常。",
        },
        {
          q: "3、评分是100分，为什么什么数据也没有？",
          a: "评分是100分表示大数据非常优秀，没有查到负面记录，报告查询的是不良的信息记录，信用极好的情况下，不良的负面信息记录为空是正常现象。",
        },
        {
          q: "4、为什么订单已经支付，还显示未支付？",
          a: "可能由于网络原因导致订单支付 通知 失败，请尝试刷新支付页面，如不能正常显示，系统会24小时内自动退款，或联系客服帮助解决。",
        },
        {
          q: "5、为什么支付成功的订单里看不到报告？",
          a: "请返回首页（填写信息的页面）点击开右侧的悬浮按钮“历史报告“，输入信息即可。",
        },
        {
          q: "6、重复支付了怎么办？",
          a: "重复支付返回首页（填写信息的页面）联系客服，客服会帮助解决，或者点击“投诉建议”留言 24小时内会有专门人员联系处理。",
        },
        {
          q: "7、怎么查看已付费的报告？",
          a: "进首页（入填写信息的页面）点击开右侧的悬浮按钮“历史报告“，输入信息即可；如果仍看不到报告，请联系客服帮助解决，报告只有一个版本请勿重复支付。",
        },
        {
          q: "8、查这个报告对自己有影响吗？",
          a: "大数据是基于各种网贷平台获取的数据，它区别于征信，和征信报告不一样，不会对自己留下查询记录。",
        },
        {
          q: " 9、报告是否提供贷款服务？",
          a: "这边是大数据查询平台，只提供查询服务，不提供任何贷款服务。",
        },
        {
          q: "10、报告一般保留多长时间？",
          a: "报告只保留七天，超过七天自动失效，建议截图保存。",
        },
        {
          q: "11、你们的报告和银行（央行）征信一样吗？",
          a: "我们这个是大数据报告，获取的是网络行为和央行征信报告是互补的。报告不是央行征信报告，报告帮助用户获取个人的网贷和司法行为，提供权威的信用评级，帮助用户检测个人信息。",
        },
        {
          q: "12、你们这个平台，能不能查到具体的借款平台，欠款金额，逾期时间？",
          a: "基于相关国家政策和隐私保护，报告不会展示具体的平台名称，金额，还款期数，会展示具体申请机构数和申请次数。",
        },
         {
          q: "13、报告很差，分数低，可以恢复吗？",
          a: "不去注册申请新的网贷，按时还款已有的网贷，3-6个月会慢慢恢复。",
        },
         {
          q: "14、你们能清理优化大数据吗？",
          a: "大数据不能清理优化，切勿相信他人以优化信用，提高信用额度等手段诈骗钱财。",
        },
         {
          q: "15、法院执行案件和法院失信人案件是什么",
          a: "此两项都是高风险数据，会严重影响风险评分，有这两项数据的人表示有官司在身或者曾经有官司在身；失信案件表示信用方面的案件，可能是欠款未还，贷款逾期等；开右侧的“历史报告“按钮，输入个人信息即可。如果已经显示结案，那么随时间变化，负面影响会越来越小。",
        },
         {
          q: "16、我的司法信息已经结案，怎么你们平台还显示执行中，能否更新？",
          a: "由于地方相关机关更新数据的时间速度不一，个别用户已结案但是未对进行更新，查询出来显示在执行中，出现此等情况请联系地方具体相关部门要求其进行数据更新。",
        },
         {
          q: "17、查到我有逾期，能知道什么平台吗？",
          a: "都是根据手机+身份证号获取的，此报告和央行征信不同，由于网贷平台层出不穷资质不一，和相关政策原因只能查询到逾期的记录，查询不到对应逾期对应平台的。就算央行征信也不能全部查到。",
        },
         {
          q: "18、其他任何问题",
          a: "如果遇到解决不了的问题，或者任何疑问，请联系客服帮助解决。",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.boss {
  width: 100vw;
  min-height: 100vh;
  background: #ebebeb;
}
.kf {
  width: 100vw;
  height: 88px;
  background: #009bdb;
  line-height: 88px;
  font-size: 32px;
  text-align: center;
  color: #fff;
}
.content {
  width: 100vw;
  height: auto;
  p {
    min-height: 100px;
    font-size: 30px;
    color: #009bdb;
    padding: 10px 20px;
    display: flex;
    align-items: center;
  }
  div {
    background: #fff;
    padding: 20px;
    line-height: 50px;
    font-size: 30px;
  }
}
</style>